.contacts {
  padding: 0;
  list-style: none;
  display: grid;
  column-gap: 4.5%;
  grid-template-columns: 49.4% 46%;
  max-width: 774px;
  min-width: 774px;
  width: 66.8%;
  margin: 0 auto 7px auto;

  @include vp-1199 {
    min-width: auto;
    width: 84%;
  }

  @include vp-1023 {
    width: 100%;
  }

  @include vp-767 {
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 27px;
    column-gap: 0;
  }
}

.contacts li {
  font-family: $ff-montserrat;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.08em;

  @include vp-1199 {
    font-size: 14px;
    line-height: 22px;
  }

  @include vp-858 {
    font-size: 13px;
  }
}

.contacts__item a {
  border-radius: 10px / 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 37px 43px 37px 43px;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  text-decoration: none;

  @include vp-767 {
    padding: 31px 31px 30px 21px;
  }

  &::after {
    content: "";
    position: absolute;
    width: calc(100% - 6px);
    height: 100%;
    border-radius: 10px / 20%;
    z-index: -1;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover::after,
  &:active::after {
    opacity: 0.5;
  }
}

.contacts__item--1 {
  max-width: 382px;

  @include vp-767 {
    max-width: 100%;
  }
}

.contacts__item--1 a {
  color: $color-medium-wood;
  background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(94.61deg, #ffefbc -9.8%, #ffe1a5 101.57%), linear-gradient(93.78deg, #e6e6e6 -17.21%, #e3e3e3 85.63%);

  &::after {
    background-color: $color-marzipan;
  }
}

.contacts__item--2 {
  max-width: 357px;

  @include vp-767 {
    max-width: 100%;
  }
}

.contacts__item--2 a {
  color: $color-rosy-brown;
  background: linear-gradient(93.5deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.51) 46.7%, rgba(255, 255, 255, 0) 94.46%), linear-gradient(140.94deg, #f2dedc 22.38%, #f4dcd5 80.4%);

  &::after {
    background-color: $color-vanilla-ice;
  }
}
