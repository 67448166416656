.about {
  padding-bottom: 104px;
  z-index: 4;
  position: relative;

  @include vp-767 {
    padding-bottom: 108px;
  }
}

.about .container {
  z-index: 2;
  display: grid;
  grid-template-columns: 47.8% 16.6% 35.6%;
  grid-template-rows: repeat(2, auto);
  row-gap: 53px;
  justify-items: center;
  position: relative;

  @include vp-1023 {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    justify-items: center;
    row-gap: 25px;
  }
}

.about__image-container {
  height: 844px;
  align-self: start;
  grid-row: 1/-1;
  grid-column: 1/3;
  margin-top: -176px;
  margin-left: -44px;
  max-width: 817px;

  @include vp-1199 {
    height: 644px;
    margin-top: -123px;
    margin-left: 0;
  }

  @include vp-1023 {
    height: 544px;
    margin-top: -97px;
    padding-left: 75px;
    grid-row: 2/3;
    grid-column: 1/-1;
  }

  @include vp-767 {
    height: 852px;
    padding-left: 0;
    margin-top: -105px;
  }
}

.about img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.about__info-wrapper {
  grid-column: 2/-1;
  grid-row: 1/2;
  z-index: 3;
  position: relative;
  max-width: 626px;

  @include vp-1023 {
    grid-column: 1/-1;
    grid-row: 1/2;
    max-width: none;
  }
}

.about__info-wrapper h1 {
  margin: 0;
  font-family: $ff-colus;
  font-weight: 400;
  font-size: 46px;
  line-height: 55px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  background: linear-gradient(265.12deg, rgba(255, 255, 255, 0) 24.67%, rgba(255, 255, 255, 0) 27.37%, rgba(255, 255, 255, 0.39) 46.56%, rgba(255, 255, 255, 0) 67.13%), $color-oyster-pink;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: 347px;
  margin-bottom: 9px;
  color: transparent;

  @include vp-1023 {
    font-size: 42px;
    line-height: 51px;
  }

  @include vp-767 {
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 5px;
    width: auto;
  }
}

.about__info-wrapper h3 {
  margin: 0;
  background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(94.61deg, #ffefbc -9.8%, #ffe1a5 101.57%);
  border-radius: 7.76px;
  font-family: $ff-montserrat;
  font-weight: 600;
  font-size: 13.96px;
  line-height: 23px;
  display: block;
  letter-spacing: 0.1em;
  color: $color-medium-wood;
  padding: 8px 14px;
  width: 138px;
  text-align: center;
  position: absolute;
  left: 228px;
  top: 7px;
  text-transform: uppercase;

  @include vp-767 {
    width: 113px;
    left: 186px;
    font-size: 11px;
    line-height: 19px;
    padding: 5px 14px 6px 14px;
  }
}

.about__info-wrapper p {
  margin: 0;
  color: $color-dark-gray;
  width: 77%;
  margin-bottom: 24px;

  @include vp-1023 {
    width: 75%;
  }

  @include vp-767 {
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    margin-bottom: 27px;
  }
}

.about__info-wrapper ul {
  margin: 0;
  padding: 0;
  padding-left: 50px;
  list-style: none;

  @include vp-767 {
    padding-left: 0;
  }
}

.about__info-wrapper li {
  font-weight: 400;
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;

  @include vp-767 {
    padding-left: 18px;
    margin-bottom: 11px;
  }

  &::before {
    content: "";
    background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(94.61deg, #ffefbc -9.8%, #ffe1a5 101.57%);
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 7px;
    left: 0;

    @include vp-767 {
      width: 8px;
      height: 8px;
    }
  }
}

.about__results-wrapper {
  grid-column: 1/-1;
  grid-row: 2/3;
  z-index: 3;

  @include vp-1023 {
    grid-row: 3/4;
    margin-top: -60px;
  }

  @include vp-767 {
    margin-top: -496px;
  }
}

.about__results-wrapper ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: 40px;
  row-gap: 60px;

  @include vp-1023 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    column-gap: 30px;
    row-gap: 50px;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    column-gap: 0;
    row-gap: 35px;
  }
}

.about__results-wrapper li {
  padding: 29px 10px 18px 11px;
  font-size: 16px;
  line-height: 24px;
  background: $color-snow;
  border-radius: 10px;
  text-align: center;
  position: relative;

  @include vp-767 {
    font-size: 12px;
    line-height: 19px;
    padding: 22px 11px 19px 11px;
  }

  &::before {
    content: "";
    background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(94.61deg, #ffefbc -9.8%, #ffe1a5 101.57%);
    display: inline-block;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;

    @include vp-767 {
      width: 28px;
      height: 28px;
    }
  }

  &::after {
    content: "";
    background-image: url("../assets/img/svg/tick-in-round.svg");
    background-size: 19px 20px;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 20px;
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);

    @include vp-767 {
      width: 15px;
      height: 15px;
      background-size: 15px 15px;
    }
  }
}
