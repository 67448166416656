.page-body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center top;

  @media (min-width: 768px) {
    background-image: url("../assets/img/content/back@1x.png");

    @include retina {
      background-image: url("../assets/img/content/back@2x.png");
    }
  }

  @include vp-767 {
    background-image: url("../assets/img/content/back-mobile@1x.png");
    background-position: center top 13%;

    @include retina {
      background-image: url("../assets/img/content/back-mobile@2x.png");
    }
  }

  @media (max-width: 558px) {
    background-position: center top 13%;
  }

  @media (max-width: 500px) {
    background-position: center top 19%;
  }

  @media (max-width: 479px) {
    background-position: center top 32%;
  }

  @media (max-width: 450px) {
    background-position: center top 94%;
  }

  @media (max-width: 443px) {
    background-position: center top -71px;
  }

  @media (max-width: 425px) {
    background-position: center top -53px;
  }

  @media (max-width: 414px) {
    background-position: center top -45px;
  }

  @media (max-width: 405px) {
    background-position: center top -30px;
  }

  @media (max-width: 398px) {
    background-position: center top -10px;
  }

  @media (max-width: 380px) {
    background-position: center top 0;
  }
}
