.reviews {
  padding-bottom: 97px;
  position: relative;
  background-size: cover;
  background-position: center center;

  @media (min-width: 768px) {
    &::before {
      content: "";
      position: absolute;
      width: 943px;
      height: 973px;
      top: 0;
      left: 0;
      z-index: 1;
      background-image: url("../assets/img/content/reviews-woman-desktop@1x.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center top -105px;

      @include retina {
        background-image: url("../assets/img/content/reviews-woman-desktop@2x.png");
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 1108px;
      height: 973px;
      top: 0;
      right: 0;
      background-image: url("../assets/img/content/reviews-lion-desktop@1x.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center top -105px;
      z-index: 1;

      @include retina {
        background-image: url("../assets/img/content/reviews-lion-desktop@2x.png");
      }
    }
  }

  @include vp-767 {
    &::before,
    &::after {
      display: none;
    }

    background-image: url("../assets/img/content/reviews-back-mobile@1x.png");
    background-size: cover;
    background-position: center center;

    @include retina {
      background-image: url("../assets/img/content/reviews-back-mobile@2x.png");
    }
  }
}

.reviews .container {
  position: relative;
  z-index: 2;
}

.reviews h3 {
  margin: 0;
  font-family: $ff-colus;
  font-weight: 400;
  font-size: 50px;
  line-height: 62px;
  display: block;
  text-transform: uppercase;
  background: linear-gradient(265.12deg, rgba(255, 255, 255, 0) 24.67%, rgba(255, 255, 255, 0) 27.37%, rgba(255, 255, 255, 0.39) 46.56%, rgba(255, 255, 255, 0) 67.13%), $color-oyster-pink;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 22px;
  text-align: center;

  @include vp-1023 {
    font-size: 42px;
    line-height: 51px;
  }

  @include vp-767 {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 8px;
  }
}

.review {
  position: relative;

  @include vp-1365 {
    width: 94%;
    margin: 0 auto;
  }

  @include vp-1299 {
    width: 761px;
  }

  @include vp-949 {
    width: 448px;
  }

  @include vp-767 {
    width: 257px;
  }
}

.review.is-nojs .review__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;

  @include vp-1365 {
    row-gap: 30px;
  }
}

.review.is-nojs .review__swiper {
  overflow: visible;
}

.review .review__swiper {
  width: 1074px;

  @include vp-1365 {
    width: 1054px;
  }

  @include vp-1299 {
    width: 706px;
  }

  @include vp-949 {
    width: 338px;
  }

  @include vp-767 {
    width: 257px;
  }
}

.review__slide {
  width: 338px;
  height: 522px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @include vp-767 {
    width: 257px;
    height: 397px;
    border-radius: 8px;
  }
}

.review__slide img {
  max-width: 100%;
  object-fit: cover;
  height: 100%;
}

.review.is-nojs .review__button {
  display: none;
}

.review__button {
  position: absolute;
  width: 78px;
  height: 78px;
  top: 54%;
  transform: translateY(-50%);
  background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(140.94deg, #f2dedc 22.38%, #f4dcd5 80.4%);
  box-shadow: 1px 3px 0 $color-vanilla-ice;
  border-radius: 120px;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.35;
  }

  @include vp-767 {
    width: 44px;
    height: 44px;
    top: auto;
    bottom: -114px;
    box-shadow: 0.56px 1.68px 0 $color-vanilla-ice;
    border-radius: 67.36px;
  }
}

.review__button--prev svg,
.review__button--next svg {
  width: 24px;
  height: 16px;

  @include vp-767 {
    width: 13px;
    height: 10px;
  }
}

.review__button--prev svg {
  transform: rotate(180deg);
}

.review__button--prev {
  left: -70px;

  @include vp-767 {
    left: 25%;
  }
}

.review__button--next {
  right: -70px;

  @include vp-767 {
    right: 25%;
  }
}
