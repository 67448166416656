.page-footer {
  padding-top: 69px;
  padding-bottom: 57px;
  background-color: $color-oyster-pink;
  color: $color-default-white;
  position: relative;
  z-index: 2;

  @include vp-767 {
    padding-top: 57px;
    padding-bottom: 67px;
  }
}

.page-footer__container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.page-footer__info-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 10px;
  column-gap: 30px;
  justify-items: start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;

  @include vp-767 {
    grid-template-rows: repeat(2, auto);
    row-gap: 26px;
    margin-bottom: 110px;
  }
}

.page-footer__logo {
  grid-column: 1/2;
  grid-row: 1/-1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 203px;

  @include vp-767 {
    grid-row: 1/2;
    width: 153px;
  }
}

.page-footer__logo p {
  margin: 0;
  font-weight: 500;
  font-size: 27px;
  line-height: 33px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @include vp-767 {
    font-size: 21px;
    line-height: 29px;
  }
}

.page-footer__logo span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;
  display: block;
  letter-spacing: 0;

  @include vp-767 {
    font-size: 14px;
    line-height: 16px;
  }
}

.page-footer__nav {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: end;
}

.page-footer__phone {
  grid-column: 2/3;
  grid-row: 2/3;
  justify-self: end;
  font-style: normal;

  @include vp-767 {
    grid-column: 1/-1;
    grid-row: 2/3;
    justify-self: start;
  }
}

.page-footer__phone a {
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  color: $color-whisper;
  text-decoration: none;

  &:hover,
  &:active {
    opacity: 0.6;
  }
}

.page-footer__meta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @include vp-767 {
    justify-content: center;
    flex-direction: column;
  }
}

.page-footer__meta p {
  font-weight: 400;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);

  @include vp-767 {
    margin-bottom: 14px;
  }
}

.page-footer__copyright {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  margin-right: auto;

  @include vp-767 {
    margin-right: 0;
  }
}

.page-footer__design {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
}
