.tabs {
  margin-bottom: 65px;

  @include vp-767 {
    margin-bottom: 0;
    padding-bottom: 154px;
  }
}

.tabs__tablinks {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 0;
  position: relative;
  width: 497px;
  margin: 0 auto 78px auto;
  justify-content: flex-start;
  z-index: 2;

  @include vp-767 {
    width: 274px;
    margin-bottom: 43px;
  }
}

.tabs__link {
  display: flex;
  font-family: $ff-montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.1em;
  position: relative;
  border: 1px solid $color-dark-gray3;
  border-radius: 10px;
  padding: 10px 25px;
  text-transform: lowercase;
  color: $color-default-grey;
  margin-right: 22px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-height: 51px;
  transition: opacity $trans-default;

  &:last-child {
    margin-right: 0;
  }

  @include vp-767 {
    font-size: 11px;
    line-height: 18px;
    padding: 4px 6px 4px 9px;
    margin-right: 10px;
    min-height: 28px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.tabs__link--text {
  width: 257px;

  @include vp-767 {
    width: 143px;
  }
}

.tabs__link--video {
  width: 218px;

  @include vp-767 {
    width: 121px;
  }
}

.tabs__link.current,
.tabs__link:hover,
.tabs__link:active,
.tabs__link:focus {
  background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(94.61deg, #ffefbc -9.8%, #ffe1a5 101.57%);
  color: $color-medium-wood;
  border: none;
}

.tabs__link.current::before,
.tabs__link:hover::before,
.tabs__link:active::before,
.tabs__link:focus::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
}

.tabs__tabcontent {
  display: none;
}

.tabs__tabcontent.current {
  display: block;
}
