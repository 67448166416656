*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: $fz-default;
  line-height: 26px;
  font-family: $ff-roboto;
  color: $color-default-grey;
  background-color: $color-default-white;
  text-align: left;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // оптимизация выравнивания шрифта относительно центра строки
  text-rendering: optimizeLegibility;
  // если по прежнему есть проблемы с выравниванием
  // https://transfonter.org/ - включите настройку https://prnt.sc/12rnt6g и переконвертируйте шрифт

  @include vp-767 {
    font-size: 14px;
    line-height: 21px;
  }
}
