.page-header {
  background-color: transparent;
  margin-bottom: 27px;
  position: relative;
  z-index: 2;

  @include vp-767 {
    margin-bottom: 22px;
  }
}

.page-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-header__logo {
  display: flex;
  width: 181px;
  height: 87px;
  background-color: $color-vanilla-ice;
  border-radius: 13px;

  @include vp-767 {
    width: 81px;
    height: 54px;
    border-radius: 6px;
  }
}

.page-header__logo img {
  max-width: 100%;

  @include vp-767 {
    width: 81px;
    height: 54px;
  }
}
