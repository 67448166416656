.intro {
  padding-bottom: 131px;
  position: relative;

  @include vp-1023 {
    padding-bottom: 103px;
  }
}

.intro .container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(6, auto);
  grid-column-gap: 0;
  justify-items: center;
  padding-bottom: 15px;

  @include vp-767 {
    grid-template-rows: repeat(5, auto);
  }
}

.intro__title {
  font-size: 82px;
  line-height: 102px;
  grid-column: 1/-1;
  grid-row: 1/2;
  font-family: $ff-colus;
  font-weight: 400;
  width: 93%;
  margin: 0 auto 0 auto;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(265.12deg, rgba(255, 255, 255, 0) 24.67%, rgba(255, 255, 255, 0) 27.37%, rgba(255, 255, 255, 0.39) 46.56%, rgba(255, 255, 255, 0) 67.13%), $color-oyster-pink;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @include vp-1199 {
    font-size: 70px;
    line-height: 59px;
  }

  @include vp-1023 {
    font-size: 50px;
    line-height: 39px;
  }

  @include vp-767 {
    font-size: 49px;
    line-height: 50px;
    width: 100%;
  }
}

.intro__title span {
  font-size: 60px;
  line-height: 75px;
  margin-top: -13px;
  display: block;

  @include vp-1199 {
    font-size: 45px;
    line-height: 30px;
    margin-top: 15px;
  }

  @include vp-1023 {
    font-size: 30px;
    line-height: 22px;
  }

  @include vp-767 {
    font-size: 17px;
    margin-top: 3px;
    padding-right: 14px;
  }
}

.intro__image-wrapper {
  grid-column: 1/-1;
  grid-row: 2/-2;
  z-index: 1;
  width: 760px;
  height: 854px;
  margin-top: -194px;
  padding-left: 55px;
  display: flex;

  @include vp-1023 {
    height: 500px;
    width: 55%;
    margin-top: -111px;
  }

  @include vp-949 {
    height: 415px;
    width: 60%;
    margin-top: -115px;
  }

  @include vp-858 {
    height: 415px;
    margin-top: -90px;
  }

  @include vp-767 {
    grid-row: 2/4;
    width: 268px;
    height: 300px;
    padding-left: 0;
    margin-left: -10px;
    margin-top: -18px;
  }
}

.intro img {
  max-width: 100%;
  object-fit: cover;
  object-position: top;
  display: block;
}

.intro__image-wrapper img {
  max-width: 100%;
  height: auto;
}

.intro__description-wrapper {
  padding: 0;
  margin: 0;
  list-style: none;
  grid-column: 1/-1;
  grid-row: 2/3;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 58px;
  width: 84%;

  @include vp-767 {
    grid-row: 3/4;
    padding-top: 0;
    width: 93%;
    max-width: 400px;
    margin-bottom: -5px;
    z-index: 3;
  }
}

.intro__description-wrapper li {
  font-family: $ff-montserrat;
  position: relative;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  min-height: 215px;

  @include vp-1023 {
    font-size: 16px;
    line-height: 24px;
  }

  @include vp-858 {
    font-size: 15px;
    line-height: 22px;
  }

  @include vp-767 {
    font-size: 14px;
    line-height: 21px;
    min-height: auto;
    color: $color-dark-gray4;
  }

  &::before {
    content: "";
    position: absolute;
    width: 102px;
    height: 94px;
    z-index: 5;
    bottom: 41px;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include vp-1023 {
      width: 80px;
      height: 74px;
      bottom: 55px;
    }

    @include vp-858 {
      width: 60px;
      height: 54px;
      bottom: 85px;
    }
  }
}

.intro__description-wrapper li:nth-child(1n) {
  width: 28%;
  text-align: right;

  @include vp-767 {
    width: 100%;
    text-align: center;
  }

  &::before {
    right: -55px;
    background-image: url("../assets/img/svg/arrow-big-left.svg");

    @include vp-858 {
      right: -25px;
    }

    @include vp-767 {
      display: none;
    }
  }
}

.intro__description-wrapper li:nth-child(2n) {
  width: 28.2%;
  text-align: left;

  @include vp-767 {
    display: none;
  }

  &::before {
    left: -55px;
    background-image: url("../assets/img/svg/arrow-big-right.svg");

    @include vp-858 {
      left: -25px;
    }
  }
}

.intro__reviews {
  grid-column: 1/-1;
  grid-row: 4/5;
  width: 57%;
  display: grid;
  grid-template-columns: 81% 2.5% 17% 26%;
  grid-template-rows: auto;
  align-items: start;
  margin-bottom: 7px;

  @include vp-1269 {
    width: 65%;
    grid-template-columns: 84% 2.5% 16% 24%;
  }

  @include vp-1023 {
    justify-content: center;
  }

  @include vp-858 {
    width: 75%;
  }

  @include vp-767 {
    grid-template-columns: 60.7% 17% 22.3%;
    grid-row: 2/4;
    align-items: center;
    padding-top: 92px;
    width: 90%;
    max-width: 238px;
    margin-left: 120px;
  }

  @media (max-width: 628px) {
    margin-left: 75px;
  }

  @media (max-width: 500px) {
    margin-left: 46px;
  }

  @media (max-width: 439px) {
    margin-left: 11px;
  }

  @media (max-width: 405px) {
    margin-left: 0;
  }

  @include vp-479 {
    width: 72%;
    padding-top: 30px;
  }
}

.intro__text-review-wrapper {
  grid-column: 1/3;
  grid-row: 1/-1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  padding: 16px 18px 10px 25px;
  font-size: 15px;
  line-height: 20px;
  max-width: 569px;
  margin: -10px auto 15px 0;
  position: relative;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  @include vp-1269 {
    max-width: none;
  }

  @include vp-767 {
    display: none;
  }
}

.intro__text-review-wrapper a {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.intro__user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 14px;
}

.intro__user div {
  width: 71px;
  height: 71px;
  background-color: $color-dark-gray;
  border-radius: 50%;
}

.intro__user img {
  max-width: 100%;
  border-radius: 50%;
  display: block;
  object-fit: cover;
}

.intro__user-name {
  font-size: 14px;
  line-height: 19px;
}

.intro__user-text {
  margin: 0;
}

.intro__link-image {
  grid-column: 2/4;
  grid-row: 1/-1;
  width: 131px;
  height: 127px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active {
    opacity: 0.8;
  }

  @include vp-1023 {
    width: 101px;
    height: 97px;
  }

  @include vp-767 {
    grid-column: 2/4;
    width: 72px;
    height: 72px;
  }
}

.intro__link-image img {
  @include vp-767 {
    width: 15px;
    height: 17px;
  }
}

.intro__link-text {
  margin: 0;
  grid-column: 4/5;
  grid-row: 1/-1;
  align-self: center;
  z-index: 6;
  max-width: 165px;
  padding-left: 25px;
  padding-top: 7px;
  font-size: 18px;
  line-height: 24px;
  position: relative;
  color: $color-grey-transparent78;

  @include vp-1023 {
    font-size: 16px;
    line-height: 24px;
  }

  @include vp-858 {
    font-size: 15px;
    line-height: 22px;
  }

  @include vp-767 {
    grid-column: 1/3;
    font-size: 13px;
    line-height: 18px;
    max-width: 139px;
    padding-left: 39px;
    padding-top: 64px;
    color: $color-suva-grey-transparent78;
  }

  &::after {
    content: "";
    position: absolute;
    width: 68px;
    height: 50px;
    z-index: 5;
    bottom: -19px;
    left: -30px;
    background-image: url("../assets/img/svg/arrow-small-left.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include vp-1023 {
      left: -10px;
    }

    @include vp-767 {
      background-image: url("../assets/img/svg/arrow-small-right.svg");
      left: 89px;
      width: 60px;
      height: 45px;
    }
  }
}

.intro__reviews--back {
  z-index: 2;
}

.intro__reviews--front {
  z-index: 6;
}

.intro__reviews--back .intro__text-review-wrapper {
  color: transparent;
  background-color: $color-white2;
  z-index: 1;
  box-shadow: 0 13px 50px rgba(67, 67, 67, 0.05);
}

.intro__reviews--front .intro__text-review-wrapper {
  background-color: transparent;
  color: $color-dark-gray-transparent78;
  box-shadow: 0 13px 50px transparent;
}

.intro__reviews--back .intro__link-image {
  background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(94.61deg, #ffefbc -9.8%, #ffe1a5 101.57%), linear-gradient(140.94deg, #f2dedc 22.38%, #f4dcd5 80.4%), linear-gradient(133.61deg, #b6f741 0%, #205531 115.27%);
  box-shadow: 1px 4px 0 $color-marzipan;
  z-index: 2;

  @include vp-767 {
    background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(94.61deg, #ffefbc -9.8%, #ffe1a5 101.57%), linear-gradient(140.94deg, #f2dedc 22.38%, #f4dcd5 80.4%), linear-gradient(133.61deg, #b6f741 0%, #205531 115.27%);
    box-shadow: 0.39px 1.57px 0 $color-marzipan;
  }
}

.intro__reviews--front .intro__link-image {
  background: none;
  box-shadow: 1px 4px 0 transparent;

  & img {
    display: none;
  }

  &:hover,
  &:active {
    background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(94.61deg, #ffefbc -9.8%, #ffe1a5 101.57%), linear-gradient(140.94deg, #f2dedc 22.38%, #f4dcd5 80.4%), linear-gradient(133.61deg, #b6f741 0%, #205531 115.27%);
    box-shadow: 1px 4px 0 $color-marzipan;
    opacity: 0.8;

    & img {
      display: block;
    }

    @include vp-767 {
      background: linear-gradient(95.94deg, rgba(255, 255, 255, 0) 5.03%, rgba(255, 255, 255, 0.47) 53.24%, rgba(255, 255, 255, 0) 102.54%), linear-gradient(94.61deg, #ffefbc -9.8%, #ffe1a5 101.57%), linear-gradient(140.94deg, #f2dedc 22.38%, #f4dcd5 80.4%), linear-gradient(133.61deg, #b6f741 0%, #205531 115.27%);
      box-shadow: 0.39px 1.57px 0 $color-marzipan;
      opacity: 0.8;
    }
  }

  @include vp-767 {
    background: none;
    box-shadow: 0.39px 1.57px 0 transparent;
  }
}

.intro__buttons {
  grid-column: 1/-1;
  grid-row: 5/6;
  z-index: 6;

  @include vp-767 {
    grid-row: 5/6;
    padding-top: 30px;
  }
}
