@font-face {
  font-style: normal;
  font-weight: 900;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../assets/fonts/roboto-black.woff2") format("woff2"),
    url("../assets/fonts/roboto-black.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../assets/fonts/roboto-bold.woff2") format("woff2"),
    url("../assets/fonts/roboto-bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../assets/fonts/roboto-medium.woff2") format("woff2"),
    url("../assets/fonts/roboto-medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../assets/fonts/roboto-regular.woff2") format("woff2"),
    url("../assets/fonts/roboto-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../assets/fonts/montserrat-bold.woff2") format("woff2"),
    url("../assets/fonts/montserrat-bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../assets/fonts/montserrat-semibold.woff2") format("woff2"),
    url("../assets/fonts/montserrat-semibold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../assets/fonts/montserrat-medium.woff2") format("woff2"),
    url("../assets/fonts/montserrat-medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Colus";

  font-display: swap;
  src:
    url("../assets/fonts/colus-regular.woff2") format("woff2"),
    url("../assets/fonts/colus-regular.woff") format("woff");
}
