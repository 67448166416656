.main-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  padding-top: 15px;

  @include vp-767 {
    padding-top: 18px;
    margin-right: -8px;
  }
}

.main-nav__item {
  margin-left: 19px;

  @include vp-767 {
    margin-left: 15px;
  }
}

.main-nav__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 40px;
  background: linear-gradient(87.08deg, #ffefbc -210.83%, #ebc77e 123.75%);
  box-shadow: inset 0 4.04px 14.8px $color-floral-white2;
  border-radius: 6.73px;
  cursor: pointer;

  &:hover,
  &:active {
    opacity: 0.6;
  }

  @include vp-767 {
    width: 33px;
    height: 34px;
  }
}

.main-nav__link svg {
  max-width: 26px;
  max-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: $color-floral-white;

  @include vp-767 {
    max-width: 21px;
    max-height: 22px;
    width: 21px;
    height: 22px;
  }
}
