.menu-footer-container {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .menu {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & .menu-item {
    padding: 0;
    margin-right: 31px;
    max-width: 32px;
    max-height: 32px;

    &:last-child {
      margin-right: 0;
    }

    @include vp-767 {
      margin-right: 24px;
    }
  }

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & a:hover,
  & a:focus {
    opacity: 0.6;
  }

  & img {
    max-width: 34px;
    max-height: 33px;
  }
}
