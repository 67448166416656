.video {
  width: 338px;
  height: 522px;
  position: relative;

  @include vp-767 {
    width: 257px;
    height: 397px;
  }
}

.video__content {
  position: relative;
}

.video a {
  display: block;
}

.video img {
  max-width: 100%;
  object-fit: contain;
}

.video__play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 54px;
  height: 54px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;

  &:hover {
    transform: scale(1.1) translate(-50%, -50%);
  }

  &:active {
    transform: scale(0.8) translate(-50%, -50%);
    opacity: 0.8;
  }
}

.video__play-button svg {
  fill: $color-default-white;
  width: 34px;
  height: 34px;

  @include vp-767 {
    width: 25px;
    height: 26px;
  }
}

.video--enabled {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video--enabled button {
  display: block;
}

.video--enabled::before {
  display: none;
}

.review__video-wrapper--double {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  align-items: center;
  row-gap: 38px;
}
