.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.swiper-slide {
  filter: blur(5px);
}

.swiper-slide-active {
  filter: none;
}
